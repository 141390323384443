body{
    background-color: aqua;
}
  
  .info-cards {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  
  .info-title {
    font-weight: bold;
    margin-bottom: 1px;
  }
  
  .card {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #007bff;
    color: white;
    font-size: 20px;
    font-weight: bold;
    padding: 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .selected {
    background-color: #5fce86;
  }

  .disabled {
    background-color: #ff0000;
  }

  .button-container {
    display: flex;
    justify-content: center;
    gap: 15px;
}

.responsive-button {
    text-wrap: nowrap;
    max-width: 100px;
    width: 100%;
    padding: 10px 10px;
    border-radius: 10px;
    border: none;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease;
}

.responsive-button:hover {
    background-color: #0056b3;
}

@media (max-width: 600px) {
    .responsive-button {
        font-size: 14px;
    }
}

  @media screen and (max-width:400px) {
      .card {
        font-size: 14px;
        padding: 8px;
      }
  }
  
  @media  (min-width:401px) and (max-width: 600px) { 
    .card {
      font-size: 14px;
      padding: 10px;
    }
  }
  