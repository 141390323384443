.game-card {
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 20px;
  transition: 0.3s;
  width: 100%;
}
.game-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.game-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.game-card-title {
    font-size: 1.2em;
    margin: 0;
}

.game-card-button {
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    padding: 10px 20px;
}

.game-card-button:hover {
    background-color: #0056b3;
}
.game-card-timer {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #007bff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.game-card-timer div {
  font-size: 1.2em;
  background-color: white;
  color: black;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  align-items: center;
}