body{
  background-color: aqua;
  color: gray;
}
.initail-title{
width: 100%;
background-color: #1f1f1f;
margin: 15px 12px;
border-radius: 3px;
padding: 10px 20px;
border: whitesmoke;
}